<template>
  <div class="cursor-pointer relative flex items-center p-5" :class="{ 'border-b border-gray-200 dark:border-dark-5': index + 1 }">
    <div class="overflow-hidden w-full">
      <div class="flex items-center gap-2">
        <app-list-item-image :value="imageUrl" type="user"></app-list-item-image>
        <div class="">
          <div class="font-medium">
            {{ userName }} <span class="text-xs text-gray-400 mx-2" v-if="isTyping">Typing...</span>
          </div>
          <div class="text-xs text-theme-10 whitespace-nowrap">ID: {{ conversation.id }}</div>
          <div class="truncate text-gray-600 dark:text-gray-300 mt-0.5 w-full">
            {{ conversation.description }}
          </div>
        </div>
      </div>
      <div class="text-xs text-gray-500" :class="isRTL ? 'mr-auto text-left' : 'ml-auto text-right'">
        <app-show-date-time :dateTime="conversation['createdAt']" date-class="notification-date"></app-show-date-time>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import FirebaseRepository from '@/shared/firebase/firebase-repository'
// import firebase from 'firebase/compat/app'
// import 'firebase/compat/firestore'
// import moment from 'moment'

export default defineComponent({
  name: 'app-chat-side-menu-item',

  props: {
    index: {
      type: Number,
      default: 0
    },
    conversation: {
      type: Object
    },
    openedComplaintId: {
      type: String,
      default: null
    }
  },
  async mounted() {
    if (!this.conversation.creator) {
      this.creator = await FirebaseRepository.findDocument('clients', this.conversation.createdBy)
    }
  },
  setup() {
    const creator = ref('')

    return {
      creator
    }
  },
  // data() {
  //   return {
  //     creator: null
  //   }
  // },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      currentUser: 'auth/currentUser',
      userTyping: 'complaintStore/isTyping',
      typingList: 'complaintStore/typingList'
    }),
    isOpened() {
      return this.conversation.id === this.openedComplaintId
    },
    isTyping() {
      // if (this.userTyping) this.scrollBottom()
      return (this.typingList.includes(this.conversation.id))
    },
    imageUrl() {
      return this.creator && this.creator.avatar
        ? this.creator.avatar
        : null
    },
    userName() {
      return this.creator && this.creator.fullName
        ? this.creator.fullName
        : this.i18n('common.elGhoneimyClient')
    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>

<style lang="scss"></style>
