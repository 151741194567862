<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('complaints.title') }}
      </h2>
    </div>

    <div class="intro-y chat grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Chat Side Menu -->
      <div class="col-span-12 lg:col-span-4 2xl:col-span-4">
        <app-chat-side-menu
          class=""
          @openConversation="openComplaint"
        ></app-chat-side-menu>
      </div>
      <!-- END: Chat Side Menu -->

      <!-- BEGIN: Chat Content -->
      <div class="intro-y col-span-12 lg:col-span-8 2xl:col-span-8">
        <div class="chat__box box">
          <!-- BEGIN: Chat Active -->
          <app-chat-content v-if="showChatBox"
            :openedComplaint="openedComplaint"
            :messages="messages"
            @doCloseComplaint="doCloseComplaint"
            @sendMessage="sendMessage"
          ></app-chat-content>
          <!-- END: Chat Active -->

          <!-- BEGIN: Chat Default -->
          <app-empty-page v-else
            :label="i18n('complaints.noMessages')"
            label-class="text-xl font-medium"
          ></app-empty-page>
          <!-- END: Chat Default -->
        </div>
      </div>
      <!-- END: Chat Content -->
    </div>
  </div>
</template>

<script>
// import Emojis from '@/views/complaint/emojis.vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import { FileUploader } from '@/shared/uploader/file-uploader'
import FirebaseRepository from '@/shared/firebase/firebase-repository'

import ChatSideMenu from '@/views/complaint/chat-side-menu.vue'
import ChatContent from '@/views/complaint/chat-content.vue'

export default defineComponent({
  components: {
    // Emojis,
    [ChatSideMenu.name]: ChatSideMenu,
    [ChatContent.name]: ChatContent
  },
  async created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.complaints')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.complaints')
    },
    complaintsStore() {
      this.complaints = this.complaintsStore || []
      // this.complaints = []
      // Object.keys(this.complaintsStore).forEach(key => {
      //   this.complaints.push(this.complaintsStore[key])
      // })
    },
    messagesStore() {
      this.scrollBottom()
      this.messages = this.messagesStore || []
      // this.messages = []
      // Object.keys(this.messagesStore).forEach(key => {
      //   this.messages.push(this.messagesStore[key])
      // })
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      complaintsStore: 'complaintStore/complaints',
      messagesStore: 'complaintStore/messages',
      showChatBox: 'complaintStore/showChatBox',
      complaintsFirestore: 'complaintStore/complaintsFirestore',
      messagesFirestore: 'complaintStore/messagesFirestore'
    })
    // messagesStore() {
    //   return this.$store._state.data.complaintStore.messages
    // },
    // complaintsStore() {
    //   return this.$store._state.data.complaintStore.complaints
    // },
    // openedComplaintImageUrl() {
    //   return this.openedComplaint && this.openedComplaint.creator && this.openedComplaint.creator.avatar
    //     ? this.openedComplaint.creator.avatar
    //     : null
    // },
    // openedComplaintUserName() {
    //   return this.openedComplaint.creator && this.openedComplaint.creator.userName
    //     ? this.openedComplaint.creator.userName
    //     : this.i18n('common.usedBrandUser')
    // }
  },
  setup() {
    const input = ref('')
    const image = ref(null)
    const messages = ref([])
    const imageUrl = ref('')
    const complaints = ref([])
    const openedComplaint = ref({})
    const chatBox = ref(false)
    const complaintMenuDropDown = ref(false)
    // const showChatBox = () => {
    //   chatBox.value = true
    // }

    return {
      messages,
      complaints,
      image,
      imageUrl,
      input,
      openedComplaint,
      complaintMenuDropDown,
      // showChatBox
      chatBox
    }
  },
  methods: {
    ...mapActions({
      fetchComplaints: 'complaintStore/fetchComplaints',
      doSendMessage: 'complaintStore/doSendMessage',
      fetchMessages: 'complaintStore/fetchMessages',
      fetchMessageSender: 'complaintStore/fetchMessageSender',
      closeComplaint: 'complaintStore/closeComplaint'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    scrollBottom() {
      setTimeout(() => {
        var container = document.querySelector('#messages-container')
        if (container) {
          container.scrollTop = container.scrollHeight
          this.textMessage = null
        }
      }, 10)
    },
    doCloseComplaint() {
      this.closeComplaint(this.openedComplaint)
      this.complaints = this.complaints.filter(
        el => el.id !== this.openedComplaint.id
      )
      this.chatBox = false
      this.complaintMenuDropDown = false
      document.getElementById('dropdown').style.display = 'none'
    },

    async openComplaint(complaint) {
      const creator = await FirebaseRepository.findDocument('clients', complaint.createdBy)
      await this.fetchMessages(complaint.id)
      await this.fetchMessageSender()
      this.messages = this.messagesStore
      this.openedComplaint = {
        ...complaint,
        creator: creator
      }
      // this.openedComplaint.creator = creator
      // this.messages = this.messagesFirestore
      // this.chatBox = true
      this.scrollBottom()
    },
    async sendMessage(message, complaint) {
      let uploads
      this.messages.push(message)
      this.scrollBottom()

      if (message.type == 'image') {
        const attachment = document.getElementById('attachments').files[0]
        const path = `complaint/${message.complaintId}`
        uploads = await FileUploader.upload(path, attachment)
      }

      const notification = {
        image: uploads ? uploads.publicUrl : null,
        title: {
          en: 'Reply to complaint',
          ar: 'الرد على الشكوى'
        },
        body: {
          en: message.message,
          ar: message.message
        },
        data: {
          key: 'complaintId',
          value: complaint.id
        }
      }
      this.doSendMessage({
        ...message,
        attachment: uploads,
        notification,
        userId: complaint.createdBy
      })
      this.uploads = {}
    }
  }
})
</script>

<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: rgb(8, 6, 6) !important;
    opacity: 1; /* Firefox */
  }
}

.isNew {
  background: red !important;
  width: 20px;
  position: absolute;
}
.chat .chat__chat-list {
  height: unset !important;
}
.no-border{
  border: unset !important
}
</style>
