<template>
  <!-- BEGIN: Chat Side Menu -->
  <!-- style="height: 782px;" -->
  <section class="h-full">
    <div class="h-full overflow-hidden h-80vh">
      <div class="intro-y">
        <div class="box p-2">
          <div class="chat__tabs nav nav-tabs justify-center" role="tablist">
            <a id="friends-tab" data-toggle="tab" data-target="#friends" href="javascript:;"
              class="flex-1 py-2 rounded-md text-center active no-border" role="tab" aria-controls="friends"
              aria-selected="false" @click="filterTab('pending')">{{ i18n('complaints.new') }}
              <div class="isNew"></div>
            </a>
            <a id="chats-tab" data-toggle="tab" data-target="#chats" href="javascript:;"
              class="flex-1 py-2 rounded-md text-center no-border" role="tab" aria-controls="chats" aria-selected="true"
              @click="filterTab('open')">{{ i18n('complaints.open') }}
            </a>
            <a id="profile-tab" data-toggle="tab" data-target="#profile" href="javascript:;"
              class="flex-1 py-2 rounded-md text-center no-border" role="tab" aria-controls="profile"
              aria-selected="false" @click="filterTab('closed')">{{ i18n('complaints.closed') }}
            </a>
          </div>
        </div>
      </div>

      <div class="box box-1 overflow-hidden mt-5">
        <div class="border-b border-gray-200 dark:border-dark-5">
          <div class="px-5 py-5">
            <div class="relative text-gray-700 dark:text-gray-300">
              <SearchIcon class="w-5 h-5 absolute mt-3" :class="isRTL ? 'mr-3' : 'ml-3'" :style="isRTL ? 'transform: rotateY(180deg);': ''" />
              <input
                type="text"
                v-model="searchText"
                @change="doFilterCompalints"
                class="form-control py-3 px-4 border-transparent bg-gray-200 placeholder-theme-0 dark:placeholder-white"
                :class="isRTL ? 'pr-10' : 'pl-10'"
                :placeholder="i18n('common.search')"
              />
                <!-- :placeholder="i18n('complaints.searchPlaceholder')" -->
              <!-- bg-gray-200 dark:bg-dark-9 -->
            </div>
          </div>
        </div>

        <div class="tab-content box-content overflow-y-auto" v-if="complaints.length">
          <div id="chats" class="tab-pane active" role="tabpanel" aria-labelledby="chats-tab">
            <div class="chat__chat-list">
              <div v-for="(complaint, index) in complaints" :key="index"
                class="intro-x"
                @click="doOpenComplaint(complaint)"
              >
                <app-chat-side-menu-item
                  :ref="reference(index)"
                  :index="index"
                  :conversation="complaint"
                  :openedComplaintId="openedComplaintId"
                ></app-chat-side-menu-item>
              </div>
            </div>
          </div>
        </div>
        <app-empty-page v-else
          :label="i18n('complaints.noComplaints')"
          label-class="text-lg font-medium"
          icon-size="3x"
        ></app-empty-page>
      </div>
    </div>
  </section>
  <!-- END: Chat Side Menu -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import ChatSideMenuItem from '@/views/complaint/chat-side-menu-item.vue'

export default defineComponent({
  name: 'app-chat-side-menu',
  components: {
    [ChatSideMenuItem.name]: ChatSideMenuItem
  },
  async created() {
    await this.fetchComplaints('pending')
  },
  setup() {
    // const openedComplaint = ref({})
    const openedComplaintId = ref(null)

    return {
      openedComplaintId
    }
  },
  data() {
    return {
      searchText: ''
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      rows: 'complaintStore/complaints'
    }),
    complaints() {
      if (this.searchText) {
        return this.rows.filter(row => {
          return row.creator.userName.toLowerCase().startsWith(this.searchText.toLowerCase()) ||
                 row.description.toLowerCase().includes(this.searchText.toLowerCase()) ||
                 row.id.toLowerCase().includes(this.searchText.toLowerCase())
        })
      }
      return this.rows
    },
    reference() {
      return (index) => {
        return 'menuItem' + index
      }
    }
  },
  methods: {
    ...mapActions({
      closeComplaint: 'complaintStore/closeComplaint',
      resetMessages: 'complaintStore/resetMessages',
      fetchComplaints: 'complaintStore/fetchComplaints'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    async filterTab(tab) {
      this.resetMessages()
      await this.fetchComplaints(tab)
    },
    async doOpenComplaint(complaint) {
      this.openedComplaintId = complaint.id
      this.$emit('openConversation', complaint)
    }
  }
})
</script>

<style lang="scss">
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  .placeholder-white {
    color: rgb(8, 6, 6) !important;
    opacity: 1;
    /* Firefox */
  }
}
.isNew {
  background: red !important;
  width: 20px;
  position: absolute;
}
.chat .chat__chat-list {
  height: unset !important;
}
.no-border {
  border: unset !important
}
</style>
<style lang="scss" scoped>
// html,
// body {
//   height: 100%;
//   margin: 0;
// }

.box-1 {
  display: flex;
  flex-flow: column;
  // height: 100%;
  height: 91%;
}

// .box-1 .row {
//   border: 1px dotted grey;
// }

.box-1 .box-header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box-1 .box-content {
  flex: 1 1 auto;
}

.box-1 .box-footer {
  flex: 0 1 40px;
}

.h-80vh {
  height: 80vh;
}
</style>
